import React from "react";
import ShahFloor from "./floor";
import './style.css'


function ShahFloors(props) {

    return (
        <div className="shah_floor_wrap">
            <div className="shah_floor_wr">
                {
                    props.floorsCount.map((arrayElement) => (
                        <ShahFloor key={arrayElement} value={arrayElement}/>
                    ))
                }

            </div>
        </div>

    )
}
export default ShahFloors;