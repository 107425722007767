import React from 'react';

const CustomButton = (props) => {
    return (
        <div className={props.className}>
            <svg viewBox="0 0 306 306">
                <polygon points="270.3,58.65 153,175.95 35.7,58.65 0,94.35 153,247.35 306,94.35   " fill="#585656"/>
            </svg>
        </div>
    );
};

export default CustomButton;