import React, {useEffect, useState} from "react";
import './style.css';
import ListItem from './list_item'
import {Link} from "react-router-dom";

function List(props) {
    const listItemInfo =
        [{
            id:1,
            roomCount:3,
            area:125,
            corpus:1,
            section:1,
            floor:2,
            number:3,
            price: 11658
        },
        {
            id:2,
            roomCount:1,
            area:350,
            corpus:1,
            section:1,
            floor:5,
            number:13,
            price: 8658
        },{
            id:3,
            roomCount:2,
            area:215,
            corpus:1,
            section:3,
            floor:6,
            number:8,
            price: 9658
        },{
            id:4,
            roomCount:2,
            area:124,
            corpus:2,
            section:3,
            floor:1,
            number:9,
            price: 11658
        }
    ]
    const headers =
        [{
            headerName: 'План',
            headerField: 'name',
            sortable: false,
            sorted: false,
            id: 10
        },
        {
            headerName: 'Кімнат',
            headerField: 'roomCount',
            sortable: true,
            sorted: false,
            id: 11
        },
        {
            headerName: 'Площа',
            headerField: 'area',
            sortable: true,
            sorted: false,
            id: 12
        },
        {
            headerName: 'Корпус',
            headerField: 'corpus',
            sortable: true,
            sorted: false,
            id: 13
        },
        {
            headerName: 'Секція',
            headerField: 'section',
            sortable: true,
            sorted: false,
            id: 14
        },
        {
            headerName: 'Етаж',
            headerField: 'floor',
            sortable: true,
            sorted: false,
            id: 15
        },
        {
            headerName: 'Номер',
            headerField: 'number',
            sortable: true,
            sorted: false,
            id: 16
        },
        {
            headerName: 'Ціна',
            headerField: 'price',
            sortable: true,
            sorted: false,
            id: 17
        },
        {
            headerName: 'Статус',
            headerField: 'status',
            sortable: false,
            sorted: false,
            id: 18
        }];
    const [headersChanges, setHeaderChanges] = useState(headers);
    const [dataChanges, setDataChanges] = useState(listItemInfo);
    const onSortChangeHandler = (item) => {
        if(item.sortable) {
            setHeaderChanges([...headersChanges.map((obj) => {
                if (item.id === obj.id) {
                    if (obj.sorted === 'sort') {
                        obj.sorted = 'sort_reverse';
                    } else {
                        obj.sorted = 'sort';
                    }
                } else {
                    obj.sorted = false;
                }
                return obj;
            })]);
        }
    };

   useEffect(() => {
        headersChanges.forEach((obj) => {
            if (obj.sorted) {
                if (obj.sorted === 'sort') {

                    setDataChanges([...dataChanges.sort((a, b) => {
                        console.log(a[obj.headerField])
                        const nameA = a[obj.headerField];
                        const nameB = b[obj.headerField];
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    })]);
                }else{
                    console.log('sort_reverse')

                    setDataChanges([...dataChanges.sort((a, b) => {
                        console.log(a[obj.headerField])
                        const nameA = a[obj.headerField];
                        const nameB = b[obj.headerField];
                        if (nameA > nameB) {
                            return -1;
                        }
                        if (nameA < nameB) {
                            return 1;
                        }
                        return 0;
                    })]);
                }
            }
        });
    }, [headersChanges]);
    return(
        <div className="list_wr">
            <div className="list_counter_wr">Найдено  <span>85</span>  квартир</div>
            <div className="list_filter_wr">
                {headersChanges.map((item) => (
                    <div
                        className={`list_filter_td ${item.sortable ? 'list_filter_td_sortable' :  ''} ${item.sorted === 'sort' ? 'sort' : item.sorted === 'sort_reverse' ? 'sort_reverse' : ''}`}
                        key={item.id} onClick={() => onSortChangeHandler(item)}>{item.headerName}
                        {item.sortable ? <div className="list_filter_arrow"></div> : ''}
                    </div>
                ))}
               {/* <div className={sortClass}>План </div>
                <div className={sortClass} onClick={sort} data-type="roomCount">Кімнат <div className="list_filter_arrow"></div></div>
                <div className={sortClass} onClick={sort} data-type="area">Площа <div className="list_filter_arrow"></div></div>
                <div className={sortClass} onClick={sort} data-type="corpus">Корпус <div className="list_filter_arrow"></div></div>
                <div className={sortClass} onClick={sort} data-type="section">Секція <div className="list_filter_arrow"></div></div>
                <div className={sortClass} onClick={sort} data-type="floor">Етаж <div className="list_filter_arrow"></div></div>
                <div className={sortClass} onClick={sort} data-type="number">Номер <div className="list_filter_arrow"></div></div>
                <div className={sortClass} onClick={sort} data-type="price">Ціна <div className="list_filter_arrow"></div></div>
                <div className={sortClass}>Статус</div>*/}
            </div>

            {dataChanges.map((item) => (
                <Link to={'/flat'}>
                    <ListItem key={item.id} roomsValue={item} />
                </Link>
            ))}

            {

                /*defaultSort.map((arrayItem,index) => (
                    <ListItem key={index} roomsValue={arrayItem} />
                ))*/

            }


        </div>

    );
}

export default List;