import React from "react";
import './style.css';

function ShahFloor(props){

    return (
        <div className="shah_floor">{props.value}</div>
    );

}

export default ShahFloor;