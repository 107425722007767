import React, {useEffect, useState, useRef} from "react";
import './style.css';
import ReactTooltip from 'react-tooltip'

function ShahParkingFlat(props) {
    const parking = props.parkingInfoValue;
    const parkingStyle = {
        //gridTemplateColumns: 'repeat('+ parking.maxFlatsOnFloor+', 30px)'
    }
    return(
        <div className='shah_parking_flat' data-tip data-for='happyFace'>    {/*double flat level*/}
            {parking.parkingName}
        </div>
    );
}
export default ShahParkingFlat;