import React, {useState} from "react"
import './style.css'
import ComplexImg from "../../shahmatka/blocks/complexImg"
import ComplexTitleAdress from "../../shahmatka/blocks/complexTitleAdress"
import BackBtn from "../../blocks/back_btn";
import PrintBtn from "../../blocks/print_btn"
import StatusDropdown from "../../blocks/status_dropdown"
import FlatExplication from "../../blocks/flat_explication"
import FlatInfoTable from "../../blocks/flat_info_table"
import FlatOnFloorBtn from "../../blocks/flat_on_floor_btn"
import FlatOnFloorMini from "../../../img/flat_on_floor_mini.png"
import SliderPagin from "../../blocks/flat_gallery"
import FlatPopUpSvg from '../../blocks/flat_pop_up_svg'
import {Link} from "react-router-dom";

function Flat(props) {
    const flatSizeInfo =
        {
            roomsTitle:'Комнат',
            roomsValue:'1 ком',
            generalAreaTitle:'Загальна пл.',
            generalAreaValue:'51,04 м2',
            livingAreaTitle:'Житлова пл.',
            livingAreaValue:'17,24 м2'
        }
    const flatInfo =
        {
            roomsTitle:'тип',
            roomsValue:'Студия',
            generalAreaTitle:'секція',
            generalAreaValue:'Б',
            livingAreaTitle:'будинок',
            livingAreaValue:'Акрополь 1'
        }
    const flatStatusDropdown =
        {
            filterItemOptions:
            [{
                optionTitle:'В продажі',
                optionStatus: true,
                optionColor: '#42b289'
            },{
                optionTitle:'Бронь',
                optionStatus: false,
                optionColor: '#767fd1'
            },{
                optionTitle:'Продано',
                optionStatus: false,
                optionColor: '#9a9a9a'
            },{
                optionTitle:'Офіс',
                optionStatus: false,
                optionColor: '#8559c8'
            },{
                optionTitle:'Акція',
                optionStatus: false,
                optionColor: '#c666c9'
            },{
                optionTitle:'Новий статус',
                optionStatus: false,
                optionColor: '#ee5844'
            }],
            placeholder: 'В продажі',
            placeholderColor: '#42b289'

    }
    const sliderImages1 =
        [
            'https://cdnimg.rg.ru/img/content/168/10/26/kotik_d_850_d_850.jpg',
            'https://cdnimg.rg.ru/img/content/166/01/17/IMG_0571_t_310x206.JPG',
            'https://cdnimg.rg.ru/img/content/168/10/26/kotik_d_850_d_850.jpg',
            'https://cdnimg.rg.ru/img/content/166/01/17/IMG_0571_t_310x206.JPG'
        ]
    const sliderImages2 =
        [
            'http://kvartoblik.dev.realist.digital/public/files/upload/full_HD.png_381863_1553249464.png',
            'http://kvartoblik.dev.realist.digital/public/files/upload/full_HD.png_381863_1553249464.png'
        ]
    const [defaulSliderImages, setSliderImages] = useState(sliderImages1)
    const [defaulFloorStatus, setFloorStatus] = useState(true)
    const [defaulPopUpView, setPopUpView] = useState('flat_slider_wr_pop')
    const [defaulPopUpSvg, setPopUpSvg] = useState('flat_svg_wr_pop')
    function FloorStatus(){
        setFloorStatus(!defaulFloorStatus)
        if(defaulFloorStatus){
            setSliderImages(sliderImages2)
        }else{
            setSliderImages(sliderImages1)
        }
    }
    function openPopUpSlider(){
        setPopUpView('flat_slider_wr_pop flat_slider_wr_pop_open')
    }
    function closePopUpSlider() {
        setPopUpView('flat_slider_wr_pop')
    }

    function openPopUpSvg(){
        setPopUpSvg('flat_svg_wr_pop flat_svg_wr_pop_open')
    }
    function closePopUpSvg(){
        setPopUpSvg('flat_svg_wr_pop');
    }


    return(
        <div className="flat_container">
            <div className="flat_container_scroll">
                <div className="shah_header">
                    <ComplexImg/>
                    <div className="shah_head_info">
                        <ComplexTitleAdress/>
                    </div>
                </div>
                <div className="flat_wrap">
                    <div className="flat_left">

                        <div className="back_btn_flat">
                            <Link to={'front/list'}>
                                <BackBtn/>
                            </Link>
                        </div>
                        <div className="flat_left_info">
                            <div className="flat_status_wrap">
                                <div className="flat_status_top">
                                    <div className="flats_status_text">статус поставив:</div>
                                    <div className="flats_status_dropdown">
                                        <StatusDropdown filterItemValue={flatStatusDropdown}/>
                                    </div>
                                </div>
                                <div className="flat_status_bot">
                                    <div className="flat_status_human_wr">
                                        <div className="flat_status_human">Андрей Савчук</div>
                                        <div className="flat_status_human_date">2017-08-16</div>
                                    </div>
                                    <div className="flat_status_human_date">по 2017-08-16</div>
                                </div>
                            </div>
                            <hr/>
                            <div className="flat_info">
                                <div className="flat_info_top">
                                    <div className="flat_title">№77</div>
                                    <div className="flat_price">714 560 грн</div>
                                </div>
                            </div>
                            <FlatInfoTable value={flatSizeInfo}/>
                            <div className="flat_expl_wrap">
                                <FlatExplication/>
                                <FlatExplication/>
                                <FlatExplication/>
                                <FlatExplication/>
                                <FlatExplication/>
                                <FlatExplication/>
                                <FlatExplication/>
                            </div>

                            <div className="flat_print">
                                <PrintBtn/>
                            </div>
                        </div>
                    </div>
                    <div className="flat_mid">

                        <div className="toggle_level_wrap">
                            <div className={`toggle_level_btn ${defaulFloorStatus ? 'selected' : ''}`} onClick={FloorStatus}>Перший ярус</div>
                            <div className={`toggle_level_btn ${defaulFloorStatus ? '' : 'selected'}`} onClick={FloorStatus}>Другий ярус</div>
                        </div>

                        <div className="flat_slider_wrap">
                            <div className="flat_slider_wr">
                                <SliderPagin images={defaulSliderImages} onclickValue={openPopUpSlider} pagination={true} activeSlide='0'/>
                            </div>
                        </div>

                    </div>
                    <div className="flat_right">
                        <FlatInfoTable value={flatInfo}/>
                        <div className="flat_on_floor_wrap">
                            <div className="flat_on_floor_title">Доступна на поверхах</div>
                            <div className="flat_on_floor_btn_wr">
                                <FlatOnFloorBtn value={'1'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'2'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'3'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'4'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'5'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'6'} color={'#767fd1'}/>
                                <FlatOnFloorBtn value={'7'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                                <FlatOnFloorBtn value={'8'} color={'#7bbd54'}/>
                            </div>
                        </div>

                        <div className="flat_floor_mini_img_wrap">
                            <div className="flat_floor_mini_img_wr" onClick={openPopUpSvg}>
                                <img className="flat_floor_mini_img" src={FlatOnFloorMini}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*POP UP SLIDER*/}
            <div className={defaulPopUpView}>
                <div className="flat_slider_pop">
                    <div className="flat_slider_close" onClick={closePopUpSlider}>
                        <svg viewBox="0 0 329.26933 329" className="flat_slider_close_icon">
                            <path fill="#646464" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                        </svg>
                    </div>

                    <SliderPagin images={defaulSliderImages} onclickValue={openPopUpSlider} pagination={false} activeSlide='0'/>

                </div>
            </div>

            {/*POP UP SVG*/}
            <FlatPopUpSvg popUpView={defaulPopUpSvg} closeEvent={closePopUpSvg}/>

        </div>
    )
}

export default Flat