import React from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams,
    useRouteMatch
} from "react-router-dom";
import ShahFloors from './floors'
import ShahHouse from './houses'
import ShahParking from './parking'
import HeaderShahmatka from './headerShahmatka'
import './style.css'
import ShahSection from "./sections"
import ReactTooltip from "react-tooltip"
import FlatImg from '../../img/flat_preview_test.png'
import ShahStatusAbdView from './blocks/statusAndView'
import Planing from '../planing'
import List from '../list'

class Shahmatka extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            data: null,
        }
        this.state = {
            floorsCount: ['4','3','2','1'],
            parkingFloorsCount: ['-1'],
            flatInfo: [
                {// dom
                    house : 1,
                    houseInfo: [
                        {//section
                            sectionName:1,
                            maxFlatsOnFloor: 3,
                            flats:[
                                {
                                    flatName: '1',
                                    flatSize: '126.00',
                                    doubleLevel : false,
                                    totalPrice: '450 000',
                                    price: '16 000',
                                    generalSize: '45.29',
                                    rooms: '2'
                                }
                            ],
                            parking:[
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                }
                            ]
                        }// end section
                    ]
                }, // end dom
                {// dom
                    house : 2,
                    houseInfo:[
                        {   //section
                            sectionName:1,
                            maxFlatsOnFloor: 5,
                            flats:[
                                {
                                    flatName: '2',
                                    flatSize: '136.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '146.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {},
                                {},
                                {
                                    flatName: '2',
                                    flatSize: '156.00',
                                    doubleLevel : true,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '166.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '176.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '186.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '196.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '206.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '216.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '226.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '226.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                },
                                {
                                    flatName: '2',
                                    flatSize: '226.00',
                                    doubleLevel : false,
                                    totalPrice: '350 000',
                                    price: '17 000',
                                    generalSize: '48.29',
                                    rooms: '2'
                                }
                            ],
                            parking:[
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                },
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                }
                            ]
                        },  // end section
                        {   //section
                            sectionName:2,
                            maxFlatsOnFloor: 3,
                            flats:[
                                {
                                    flatName: '3',
                                    flatSize: '146.00',
                                    doubleLevel : false,
                                    totalPrice: '550 000',
                                    price: '18 000',
                                    generalSize: '78.29',
                                    rooms: '3'
                                }
                            ],
                            parking:[
                                {
                                    parkingName: 'П',
                                    parkingPrice: '16 000',
                                }
                            ]
                        } // end section
                    ]
                } // end dom

            ]
        }
    }
    componentDidMount() {
       /* fetch('http://realbase.dev.realist.digital/ajax/test.php')
            .then(response => response.json())
            .then(data => {
                this.setState({data})
                console.log(this.state.data);
            });*/
    }
    render() {
        const {floorsCount,  flatInfo, parkingFloorsCount} = this.state
        return(
            <div className="shah_container">
                <HeaderShahmatka />
                <Switch>
                    <Route exact path='/front'>

                        <div className="view_front_wrap">
                            <ShahStatusAbdView />
                            <div className="shah_house_wrap">
                                <ShahFloors floorsCount={floorsCount}/>
                                {
                                    flatInfo.map((arrayElement, index) => (
                                        <ShahHouse key={index}  flatInfoValue={arrayElement}/>
                                    ))
                                }
                                <ShahFloors floorsCount={floorsCount}/>
                                <ReactTooltip id='happyFace' aria-haspopup='true' place='top' className="list_flat_info_pop">
                                    <div className="list_flat_info_wrap" >
                                        <div className="list_flat_info">
                                            <img src={FlatImg} className="list_flat_img" alt="room" />
                                        </div>
                                        <div className="list_flat_info">
                                            <p className="list_flat_price">100 000 долл.</p>
                                            <p className="list_flat_price_m">1000 долл/м²</p>
                                        </div>
                                        <div className="list_flat_info_inner_wrap">
                                            <div>
                                                <div className="list_flat_type">2a</div>
                                                <div className="list_flat_num">№25</div>
                                            </div>
                                            <div>
                                                <div className="list_flat_rooms">2 кімн.</div>
                                                <div className="list_flat_price_m">102.25 м²</div>
                                            </div>
                                        </div>
                                        <div></div>
                                    </div>
                                </ReactTooltip>
                            </div>
                            {/* PARKINKG*/}
                            <div className="shah_parking_wrap">
                                <ShahFloors floorsCount={parkingFloorsCount}/>
                                {
                                    flatInfo.map((arrayElement, index) => (
                                        <ShahParking key={index}  flatInfoValue={arrayElement}/>
                                    ))
                                }
                                <ShahFloors floorsCount={parkingFloorsCount}/>
                            </div>
                        </div>
                    </Route>
                    <Route path='/front/fasads'>
                        <ShahStatusAbdView />
                        Фасады
                    </Route>
                    <Route path={'/front/planing'}>
                        <div className="planing_wrap">
                            <ShahStatusAbdView />
                            <Planing />
                        </div>
                    </Route>
                    <Route path={'/front/list'}>
                        <div className="list_wrap">
                            <ShahStatusAbdView />
                            <List />
                        </div>
                    </Route>
                </Switch>
            </div>
        );
    }
}
export default Shahmatka;