import React, {useEffect, useRef, useState} from "react";
import IconEye from '../../../img/icon_eye.png';
import './style.css';
import ShahFlat from '../flat'

function ShahSection(props) {
    const sectionInfoValue = props.sectionInfoValue
    const flats = props.sectionInfoValue.flats
    const sectionStyle = {
        gridTemplateColumns: 'repeat('+ sectionInfoValue.maxFlatsOnFloor+', 30px)'
    }


    useEffect(()=>{

    })

    // show eye btn on mouseover
    const floorBtnRef = useRef();
    const [iconEyeTop, setIconEyeTop] = useState({top: 0});
    function HoverFlat(e) {
        const flatPosition = e.target.offsetTop
        let level2Height = 30
        floorBtnRef.current.classList.add('show')
        if(e.target.classList.contains('chicho')){
            floorBtnRef.current.style.top = flatPosition + level2Height + 2+ 'px'
        }else{
            floorBtnRef.current.style.top = flatPosition + 'px'
        }
    }

    return (
        <div className="shah_section">
            <div className="shah_section_title">Секція {sectionInfoValue.sectionName}</div>

            <div className="shah_flat_wrap" >
                <div className="shah_flat_wr" style={sectionStyle}>
                    <div className="list_floor_btn_wrap" id="list_floor_btn_wrap" ref={floorBtnRef} style={iconEyeTop}>
                        <div className="list_floor_btn_icon_wr">
                            <img src={IconEye} className="list_floor_btn_icon"/>
                        </div>
                    </div>

                    {
                        flats.map((elem, index) =>(
                            <ShahFlat key={index} flatInfoValue={elem} onMouseEnterEvent={HoverFlat} />
                        ))
                    }

                </div>
            </div>
        </div>

    )
}
export default ShahSection;