import React from "react";
import IconEye from '../../../../img/icon_eye.png';
import './style.css';

function ShahParkingSectionTitle(props) {
    const sectionInfoValue = props.sectionInfoValue;
    const parking = props.sectionInfoValue.parking;
    const sectionStyle = {
        gridTemplateColumns: 'repeat('+ sectionInfoValue.maxFlatsOnFloor+', 30px)'
    }
    // console.log(sectionInfoValue.maxFlatsOnFloor,' sectionInfoValue.maxFlatsOnFloor');
    return (
        <div className="shah_section">
            <div className="shah_section_title">Секція {sectionInfoValue.sectionName}</div>

            <div className="shah_flat_wrap" >
                <div className="shah_flat_wr" style={sectionStyle}>

                </div>
            </div>
        </div>

    )
}
export default ShahParkingSectionTitle;