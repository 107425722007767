import React, {createRef} from "react";
import './style.css'
import FilterItem from '../../blocks/filter_item_dropdown'
import FilterItemBtn from '../../blocks/filter_item_btn'
import ComplexImg from '../blocks/complexImg'
import ComplexTitleAdress from '../blocks/complexTitleAdress'

class HeaderShahmatka extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            filterItemHouses :  {
                filterItemTitle: 'ВИБІР БУДИНКУ',
                filterItemOptions:
                    [{
                        optionTitle:'Всі будинки',
                        optionStatus: true
                    },{
                        optionTitle:'Дом 1',
                        optionStatus: false
                    },{
                        optionTitle:'Дом 2',
                        optionStatus: false
                    }],
                filterItemInputs: false,
                placeholder: 'Всі будинки'
            },
            filterItemPrice :  {
                filterItemTitle: 'Вартість',
                filterItemOptions:
                    [{
                        optionTitle:'будь-яка',
                        optionStatus: true
                    },{
                        optionTitle:'до 400 000',
                        optionStatus: false
                    },{
                        optionTitle:'до 800 000',
                        optionStatus: false
                    },{
                        optionTitle:'до 1 500 000',
                        optionStatus: false
                    },{
                        optionTitle:'до 2 000 000',
                        optionStatus: false
                    }],
                filterItemInputs: true,
                placeholder: 'будь-яка'
            },
            filterItemRepairs :  {
                filterItemTitle: 'Ремонт',
                filterItemOptions:
                    [{
                        optionTitle:'-',
                        optionStatus: true
                    },{
                        optionTitle:'З ремонтом',
                        optionStatus: false
                    },{
                        optionTitle:'Без ремонту',
                        optionStatus: false
                    }],
                filterItemInputs: false,
                placeholder: '-'
            },
            filterItemArea :  {
                filterItemTitle: 'Площа',
                filterItemOptions:
                    [{
                        optionTitle:'будь-яка',
                        optionStatus: true
                    },{
                        optionTitle:'від 25 м²',
                        optionStatus: false
                    },{
                        optionTitle:'від 45 м²',
                        optionStatus: false
                    },{
                        optionTitle:'від 65 м²',
                        optionStatus: false
                    },{
                        optionTitle:'від 100 м²',
                        optionStatus: false
                    }],
                filterItemInputs: true,
                placeholder: '-'
            },
            filterItemDop :  {
                filterItemTitle: 'Додатково',
                filterItemOptions:
                    [{
                        optionTitle:'-',
                        optionStatus: true
                    },{
                        optionTitle:'--',
                        optionStatus: false
                    }],
                filterItemInputs: false,
                placeholder: '-'
            },
            filterItemDateFinish :  {
                filterItemTitle: 'ДАТА ЗДАЧІ',
                filterItemOptions:
                    [{
                        optionTitle:'-',
                        optionStatus: true
                    },{
                        optionTitle:'1 кв 2020',
                        optionStatus: false
                    },{
                        optionTitle:'4 кв 2020',
                        optionStatus: false
                    },{
                        optionTitle:'3 кв 2021',
                        optionStatus: false
                    }],
                filterItemInputs: false,
                placeholder: '-'
            },
        }
    }

    render() {
        return (
            <div className="shah_header">
                <ComplexImg/>
                <div className="shah_head_info">
                    <ComplexTitleAdress/>
                    <div className="shah_head_bot">
                        <FilterItem filterItemValue={this.state.filterItemHouses} />
                        <FilterItemBtn/>
                        <FilterItem filterItemValue={this.state.filterItemPrice} />
                        <FilterItem filterItemValue={this.state.filterItemRepairs} />
                        <FilterItem filterItemValue={this.state.filterItemArea} />
                        <FilterItem filterItemValue={this.state.filterItemDop} />
                        <FilterItem filterItemValue={this.state.filterItemDateFinish} />
                    </div>
                </div>

            </div>
        )
    }
}

export default HeaderShahmatka;