import React, {useEffect, useState, useRef} from "react";
import ReactDOM from 'react-dom';
import './style.css';
import ReactTooltip from 'react-tooltip'

function ShahFlat(props) {
    const flat = props.flatInfoValue;
    /*useEffect(()=>{
        function infoWindow () {
            console.log('ropik');
            const shahFlat = document.querySelectorAll('shah_flat');
            function handleEnter() {
                for (let i = 0; i < shahFlat.length; i++ ) {
                    var this_position = shahFlat[i].offset();
                    var body_height = window.innerHeight/ 2;
                    var body_width = window.innerWidth / 2;
                    if(this_position.top>body_height){
                        shahFlat[i].classList.add('shah_flat_bot');
                    }else{
                        shahFlat[i].classList.remove('shah_flat_bot');
                    }
                    if(this_position.left>body_width){
                        shahFlat[i].classList.add('shah_flat_left');
                    }else{
                        shahFlat[i].classList.remove('shah_flat_left');

                    }
                }
            }

            handleEnter();
            window.addEventListener('resize', handleEnter);
        }
        infoWindow ();
    })*/
   /* let shahFlatRef = useRef(null);
    useEffect(()=>{

        let popUpPosition = '';
        if(shahFlatRef.offset().top>window.innerHeight/ 2){
            let popUpPosition = 'shah_flat_bot';
        }else{
            let popUpPosition = '';
        }
    })*/

    return(
        !flat.flatName ?    // Empty flat
            <div className="shah_flat empty" ></div>
        :
            <div className={`shah_flat ${ flat.doubleLevel ? "chicho " : '' }`} data-tip data-for='happyFace' onMouseMove={props.onMouseEnterEvent} >    {/*double flat level*/}
                {flat.flatName}
                {/*<div className="list_flat_info_wrap" >
                    <div className="list_flat_info">

                    </div>
                    <div className="list_flat_info">
                        <p className="list_flat_price">{flat.totalPrice} долл.</p>
                        <p className="list_flat_price_m">{flat.price} долл/м²</p>
                    </div>
                    <div className="list_flat_info_inner_wrap">
                        <div>
                            <div className="list_flat_type">{flat.flatName}</div>
                            <div className="list_flat_num">№25</div>
                        </div>
                        <div>
                            <div className="list_flat_rooms">{flat.rooms} кімн.</div>
                            <div className="list_flat_price_m">{flat.flatSize} м²</div>
                        </div>
                    </div>
                    <div></div>
                </div>*/}
            </div>
    );
}
export default ShahFlat;