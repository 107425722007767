import React from 'react'
import './style.css'
const FlatExplication = (props) => {
    return (
        <div className="flat_expl_tr">
            <div className="flat_expl_title">Кухня-вітальня</div>
            <div className="flat_expl_size">18,79 м<sup>2</sup></div>
        </div>
    );
};

export default FlatExplication;