import React from 'react'
import './style.css'

const PrintBtn = () => {
    return (
        <div className="print_btn_wr">
            <div className="print_btn_text">Друк</div>
            <svg viewBox="0 0 17 17" className="print_btn_svg">
                <path fill="#fff" d="M4,12.5v3h8v-3v-2H4V12.5z M5,11.5h6v1H5V11.5z M5,13.5h6v1H5V13.5z"></path>
                <polygon fill="#fff" points="12,3.5 12,0.5 4,0.5 4,3.5 4,5.5 12,5.5"></polygon>
                <path fill="#fff" d="M14,3.5h-1v2v1H3v-1v-2H2c-1,0-2,1-2,2v5c0,1,1,2,2,2h1v-2v-1h10v1v2h1c1,0,2-1,2-2v-5    C16,4.5,15,3.5,14,3.5z"></path>
            </svg>
        </div>
    );
};

export default PrintBtn