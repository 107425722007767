import React from "react";
import './style.css';
import PlaningItem from './planing_item'


function Planing(props) {
    return(
        <div className="">
            <div className="planing_title">1- спальневі планування</div>
            <div className="planing_wr">
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
                <PlaningItem/>
            </div>
        </div>
    );
}

export default Planing;