import React from 'react'
import './style.css'

const FlatPopUpSvg = (props) => {
    return (
        <div className={props.popUpView}>
            <div className="flat_svg_pop">
                <div className="flat_slider_close" onClick={props.closeEvent}>
                    <svg viewBox="0 0 329.26933 329" className="flat_slider_close_icon">
                        <path fill="#646464" d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"/>
                    </svg>
                </div>

                <div className="flat_svg_wr">
                    <img src="https://shengen.com.ua/public/files/flats/shengen/d3/s2f2_7.png" className="flat_pop_svg_img"/>
                    <div id="flat_svg">
                        <svg viewBox="0 0 1065 495">
                            <a href="" className="flat_svg_link_default">
                                <path fill="#ffcf00" stroke="#007fc7" d="M200,261L200,479L391,479L391,495L498,495L498,261Z" stroke-width="0" fill-opacity="0.8" opacity="0" font="4565" font-style="0"></path>
                            </a>
                        </svg>
                    </div>
                    <div className="marker_floor">1_3A</div>
                </div>

            </div>
        </div>
    );
};

export default FlatPopUpSvg