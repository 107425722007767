import React, {useEffect,useRef, useState} from "react"
import './style.css'
import ShahParkingSection from './parking_section'
import ShahParkingSectionTitle from '../blocks/section_title'
import ShahParkingFlat from './parking_section/parking_flat'

function ShahParking(props) {
    const flatInfoValue = props.flatInfoValue
    const sections = props.flatInfoValue.houseInfo
    const targetRef = useRef();
    const targetParentRef = useRef();

    const [height, setHeight] = useState({height:0, status:false});
    const [dropdownStatus,setDropdownStatus] = useState(false)

    function toggleDropdown() {
        setDropdownStatus(!dropdownStatus)
        setHeight({
            height: height.status ? 0 : targetRef.current.offsetHeight + "px" ,
            status: !height.status
        })
    }

    return(
        <div className="shah_parking_house_wr">

            <div className="shah_parking_section_wr">
                {
                    sections.map((elem, index) =>(
                        <ShahParkingSectionTitle key={index} sectionInfoValue={elem} />
                    ))
                }
            </div>

            <div className="shah_parking_preview_wr">
                <div className="shah_parking_preview" onClick={toggleDropdown}>
                    <div className="shah_parking_preview_text">Паркінги</div>
                    <div className="shah_parking_preview_td">
                        <div className="shah_parking_preview_status" style={{backgroundColor: '#4995d0'}}></div>
                        <div className="shah_parking_preview_number">26</div>
                    </div>
                    <div className="shah_parking_preview_td">
                        <div className="shah_parking_preview_status" style={{background: '#e91048'}}></div>
                        <div className="shah_parking_preview_number">26</div>
                    </div>
                    <div className="shah_parking_preview_td">
                        <div className="shah_parking_preview_status" style={{background: '#aeaeae'}}></div>
                        <div className="shah_parking_preview_number">26</div>
                    </div>
                </div>
            </div>

            <div className={`shah_parking_dropdown_wr ${dropdownStatus ? 'dropdown_open' : ''}`} ref={targetParentRef} style={height}>
                <div className="shah_parking_dropdown" ref={targetRef}>
                    {
                        sections.map((item, index) => item.parking.map((elem,index) => (
                            <ShahParkingFlat key={index} parkingInfoValue={elem}/>
                        )) )
                    }
                </div>
            </div>


        </div>
    );
}

export default ShahParking;