import React, {useEffect} from "react";
import './style.css'

function ListItem(props){

    return (
        <div className="list_item_wr">
            <div className="list_item_td">
                <img src="http://kvartoblik.dev.realist.digital/public/files/upload/4_house_1_sec_floor_3-11_2a.png_457638_1488272841.png"/>
            </div>
            <div className="list_item_td"> {props.roomsValue.roomCount}-кімн</div>
            <div className="list_item_td">{props.roomsValue.area} м<sup>2</sup></div>
            <div className="list_item_td">№{props.roomsValue.corpus}</div>
            <div className="list_item_td">{props.roomsValue.section}</div>
            <div className="list_item_td">{props.roomsValue.floor}</div>
            <div className="list_item_td">№{props.roomsValue.number}</div>
            <div className="list_item_td">
                <div className="list_item_td_big">
                    <div className="">{props.roomsValue.price} <span>грн/м<sup>2</sup></span></div>
                    <div className="">6 731 032 <span>грн/м<sup>2</sup></span></div>
                </div>
            </div>
            <div className="list_item_td">
                <div className="list_item_status"  style={{backgroundColor: 'rgb(66, 178, 137)'}}></div>
            </div>
        </div>
    )

}

export default ListItem;