import React from "react";
import './style.css';
import ShahSection from '../sections'

function ShahHouse(props) {
    const flatInfoValue = props.flatInfoValue;
    const sections = props.flatInfoValue.houseInfo;
   // console.log(sections.length+ ' props.sections');
   // console.log(house+ ' props.house');
    return(
        <div className="shah_house_wr">
            <div className="shah_house_title">Будинок {flatInfoValue.house}</div>
            <div className="shah_section_wr">
                {
                    sections.map((elem, index) =>(
                        <ShahSection key={index} sectionInfoValue={elem} />
                    ))

                }
            </div>
        </div>
    );
}

export default ShahHouse;