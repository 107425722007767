import React from "react"
import './style.css'

function BackBtn() {
    return(
        <div className="back_btn">
            <svg viewBox="0 0 306 306" className="back_btn_arrow">
                <polygon points="270.3,58.65 153,175.95 35.7,58.65 0,94.35 153,247.35 306,94.35   " fill="#7d7d7d"/>
            </svg>
            <div className="back_btn_text">повернутись</div>
        </div>
    )
}
export default BackBtn

