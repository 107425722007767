import React, { useState,useEffect } from 'react'
import Swiper from 'react-id-swiper';
import CustomButton from './customButton';
import 'swiper/css/swiper.css'
import './style.css'

const SliderPagin = (props) => {

    const [gallerySwiper, getGallerySwiper] = useState(null);
    const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
    const gallerySwiperParams = {
        getSwiper: getGallerySwiper,
        spaceBetween: 0,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        renderPrevButton: () => <CustomButton className="swiper-button-prev swiper_prev_btn">Prev</CustomButton>,
        renderNextButton: () => <CustomButton className="swiper-button-next swiper_next_btn">Next</CustomButton>,
        shouldSwiperUpdate:true
    };

        const thumbnailSwiperParams = {
            getSwiper: getThumbnailSwiper,
            spaceBetween: 10,
            slidesPerView: 3,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            centeredSlides: true,
            shouldSwiperUpdate:true,
        };

    useEffect(() => {
        if (
            gallerySwiper !== null &&
            gallerySwiper.controller &&
            thumbnailSwiper !== null &&
            thumbnailSwiper.controller
        ) {
            if(props.activeSlide) {
                gallerySwiper.activeSlideKey = props.activeSlide
            }else{
                gallerySwiper.slideTo(0)
            }
            gallerySwiper.controller.control = thumbnailSwiper;
            thumbnailSwiper.controller.control = gallerySwiper;
        }
    }, [gallerySwiper, thumbnailSwiper]);



    return (
        <div className="slider_cont">
            <div className="slider_wrap">
                <Swiper {...gallerySwiperParams} activeSlideKey={props.activeSlide}>
                    {props.images.map((item, index)=>(
                        <div className="slider_slide" onClick={props.onclickValue} key={String(index)} >
                            <img  src={item}/>
                        </div>
                    ))}
                </Swiper>
            </div>
            {props.pagination && (
            <div className="slider_pagin_wrap">
                <Swiper {...thumbnailSwiperParams} activeSlideKey={props.activeSlide}>
                    {props.images.map((item, index) => (
                        <div key={String(index)} style={{backgroundImage: 'url(' + item + ')'}}/>
                    ))}
                </Swiper>
            </div>
            )}
        </div>
    );
};

export default SliderPagin;