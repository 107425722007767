import React, {useEffect} from "react";
import './style.css'

function PlaningItem(props){
    return (
        <div className="planing_item_wr">
            <div className="planing_item_top">
                <div className="planing_item_flat_count">17 квартир знайдено</div>
                <div className="planing_item_flat_arrow"></div>
            </div>
            <div className="planing_item_mid">
                <img src="http://kvartoblik.dev.realist.digital/public/files/upload/4_house_1_sec_floor_3-11_2a.png_457638_1488272841.png"/>
            </div>
            <div className="planing_item_bot">
                <div className="planing_item_type">Студія</div>
                <div className="planing_item_size">34 м<sup>2</sup></div>
            </div>
        </div>
    )

}

export default PlaningItem;