import React from 'react';
import './style.css'

const FlatInfoTable = (props) => {
    return (
        <div className="flat_size_wr">
            <div className="flat_size_title">
                <div className="flat_size_title_td">{props.value.roomsTitle}</div>
                <div className="flat_size_title_td">{props.value.generalAreaTitle}</div>
                <div className="flat_size_title_td">{props.value.livingAreaTitle}</div>
            </div>
            <div className="flat_size">
                <div className="flat_size_td">{props.value.roomsValue}</div>
                <div className="flat_size_td">{props.value.generalAreaValue}</div>
                <div className="flat_size_td">{props.value.livingAreaValue}</div>
            </div>
        </div>
    );
};

export default FlatInfoTable;