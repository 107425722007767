import complexPreviewImg from "../../../../img/flat_complex_preview.jpg";
import React from "react";
import './style.css'

function ComplexImg() {
    return(
        <div className="shah_head_preview_img_wr">
            <img src={complexPreviewImg} alt="" className="shah_preview_img"/>
        </div>
    )
}
export default ComplexImg
