import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Shahmatka from '../../shahmatka'
import MenuLeft from '../../menuLeft'
import Flat from '../flat'
import './style.css'

class Main extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuLeftOpen: true
        }
    }

    changePanel = () => {
        this.setState({menuLeftOpen:!this.state.menuLeftOpen})
    }

    render(){
        return(
            <div className={`container ${this.state.menuLeftOpen ? '222' : 'panel_mini'}`}>

                <div className="flex height_100">
                    <div className="work_container ">
                        <MenuLeft changePanelValue={this.changePanel}/>
                        <Router>
                            <Switch>
                                <Route path="/front">
                                    <Shahmatka/>
                                </Route>
                                <Route exact path="/flat">
                                    <Flat/>
                                </Route>
                            </Switch>
                        </Router>

                    </div>
                </div>

            </div>
        )
    }
}

export default Main;
