import React from "react";
import './style.css'
import StatusItem from '../../../blocks/status'
import ViewBtn from '../../blocks/view_btn'
class ShahStatusAbdView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="shah_view_btns_wrap">
                <div className="status_wrap">
                    <div className="status_wrap">
                        <StatusItem bgValue={'#42b289'}/>
                    </div>
                    <div className="status_wrap">
                        <StatusItem bgValue={'#767fd1'}/>
                    </div>
                    <div className="status_wrap">
                        <StatusItem bgValue={'#9a9a9a'}/>
                    </div>
                    <div className="status_wrap">
                        <StatusItem bgValue={'#8559c8'}/>
                    </div>
                    <div className="status_wrap">
                        <StatusItem bgValue={'#c666c9'}/>
                    </div>
                    <div className="status_wrap">
                        <StatusItem bgValue={'#ee5844'}/>
                    </div>
                </div>
                <div className="shah_view_btns_wr">
                    <ViewBtn urlValue={'/front/fasads'} iconValue={'fasads'} titleValue={'Фасади'}/>
                    <ViewBtn urlValue={'/front'} iconValue={'shahmatka'} titleValue={'Шахматка'}/>
                    <ViewBtn urlValue={'/front/planing'} iconValue={'plans'} titleValue={'Планування'}/>
                    <ViewBtn urlValue={'/front/list'} iconValue={'list'} titleValue={'Список'}/>
                </div>

            </div>
        )
    }
}

export default ShahStatusAbdView;