import React, {useState} from 'react'
import './style.css'
import StatusDropdownOption from '../../blocks/status_dropdown/status_dropdown_option'

const StatusDropdown = (props) => {
    const optionArray = props.filterItemValue.filterItemOptions
    // check option
    const [defaultActiveOption, setActiveOption] = useState(optionArray)
    const [defaultPlaceholder, changePlaceholder] = useState(props.filterItemValue.placeholder);
    const [defaultPlaceholderColor, changePlaceholderColor] = useState(props.filterItemValue.placeholderColor);
    function optionClick(key, item) {
        setActiveOption([...defaultActiveOption.map((item) => {
            key === defaultActiveOption.indexOf(item) ? item.optionStatus = true : item.optionStatus = false
            return item
        })])
        changePlaceholder(item.optionTitle)
        changePlaceholderColor(item.optionColor)
    }
    return (
        <div className="status_dropdown">
            <div className="select_status_dropdown">
                <div className="select_status_current_icon" style={{backgroundColor:defaultPlaceholderColor}}></div>
                <input className="status_dropdown_current" placeholder={defaultPlaceholder} disabled/>
                <div className="status_dropdown_option_wrap">
                    <div className="status_dropdown_option_wr">
                        {
                            optionArray.map((item,index)=>(
                                /*<FilterItemOption className={defaultActiveOption[index].optionStatus ? 'option active' : 'option'} optionsValue={item} key={index}  optionClickValue={() => optionClick(index, item)}/>*/

                                <StatusDropdownOption key={index} className={defaultActiveOption[index].optionStatus ? 'status_dropdown_option active' : 'status_dropdown_option'} optionClickValue={() => optionClick(index, item)} titleValue={optionArray[index].optionTitle} bgValue={optionArray[index].optionColor}/>
                            ))
                        }


                       {/* <button className="status_dropdown_option">
                            <span className="status_dropdown_option_icon"></span>
                            <span>Бронь</span>
                        </button>*/}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusDropdown