import React from "react";
import './style.css'

function FilterItemBtn(props) {
    return(
        <div className="filter_item">
            <div className="filter_label">222 БУДИНКУ</div>
            <div className="filter_btns_wr">
                <div className="filter_btns_link">1</div>
                <div className="filter_btns_link">2</div>
                <div className="filter_btns_link">3</div>
                <div className="filter_btns_link">4</div>
            </div>
        </div>
    )
}

export default FilterItemBtn