import React from "react"
import './style.css'
function ComplexTitleAdress() {
    return(
        <div className="shah_head_top">
            <div className="shah_head_complex_title">ЖК “Французский квартал”</div>
            <div className="shah_head_complex_adress">Киев, Большая Васильковская, 25</div>
        </div>
    )
}
export default ComplexTitleAdress

