import React from "react";
import './style.css'

function StatusItem(props){
    //console.log(typeof(props.bgValue))
    return (
        <div className="status_wr">
            <div className="status_color" style={{backgroundColor: props.bgValue}}></div>
            <div className="status_text">В продажі</div>
        </div>
    )

}

export default StatusItem;