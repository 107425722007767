import React, {useEffect, useRef, useState} from "react"
import './style.css'
import FilterItemOption from './filter_item_option'
import ShahHouse from "../../shahmatka/houses"

function FilterItem(props) {
    const optionArray = props.filterItemValue.filterItemOptions

    const inputFromRef = useRef(null);
    const inputToRef = useRef(null);
    const [defaultPlaceholder, changePlaceholder] = useState(props.filterItemValue.placeholder);
    //const [defaultInputFrom, changeInputFrom] = useState('від');
    //const [defaultInputTo, changeInputTo] = useState('до');

    function inputKeyUp(e) {
       /* changeInputFrom('від')
        changeInputTo('до')

        if (defaultInputFrom === '' && defaultInputTo === '') {
            changeValue('Всі будинки')
            changeInputFrom('від')
            changeInputTo('до')
            setActiveOption(defaultActiveOption.map((item, index) => {
                item.optionStatus = optionArray[index].optionStatus
                return item
            }))
            return
        }
        console.log(defaultInputFrom)
        console.log(defaultInputTo)
        if (defaultInputFrom != 'від' && defaultInputTo === 'до') {
            changeValue('від ' + e.target.value);
        } else if (inputFromRef.current.value === '' && inputToRef.current.value != '') {
            changeValue('від 0 до ' + defaultInputFrom);
        } else {
            changeValue('від ' + e.target.value + ' до ' + inputToRef.current.value);
        }
        setActiveOption(defaultActiveOption.map((item, index) => {
            item.optionStatus = false
            return item
        }))*/
        if (inputFromRef.current.value === '' && inputToRef.current.value === '') {
            //changeValue('Всі будинки')
            changePlaceholder(props.filterItemValue.placeholder)
            //changeInputFrom('від')
            setActiveOption(defaultActiveOption.map((item, index) => {
                item.optionStatus = optionArray[index].optionStatus
                return item
            }))
            return
        }
        if (inputFromRef.current.value != '' && inputToRef.current.value === '') {
            changePlaceholder('від ' + inputFromRef.current.value);
        } else if (inputFromRef.current.value === '' && inputToRef.current.value != '') {
            changePlaceholder('від 0 до ' + inputToRef.current.value);
        } else {
            changePlaceholder('від ' + inputFromRef.current.value + ' до ' + inputToRef.current.value);
        }
        setActiveOption(defaultActiveOption.map((item, index) => {
            item.optionStatus = false
            return item
        }))
    }

    // check option
    const [defaultActiveOption, setActiveOption] = useState(optionArray)
    function optionClick(key, item) {
        setActiveOption([...defaultActiveOption.map((item) => {
            key === defaultActiveOption.indexOf(item) ? item.optionStatus = true : item.optionStatus = false
            return item
        })])
        changePlaceholder(item.optionTitle)
        if(props.filterItemValue.filterItemInputs) {
            inputFromRef.current.value = ''
            inputToRef.current.value = ''
        }
    }
    return(
        <div className="filter_item">
            <div className="filter_label">{props.filterItemValue.filterItemTitle}</div>
            <div className="select_item_wrap">
                <input className="current"  placeholder={defaultPlaceholder} disabled />
                <div className="option_wrap">
                    {
                        optionArray.map((item,index)=>(
                            /*<FilterItemOption className={index === defaultActiveOption  ? 'option active' : 'option'} optionsValue={item} key={index}  optionClickValue={() => optionClick(index, item)}/>*/
                            <FilterItemOption className={defaultActiveOption[index].optionStatus ? 'option active' : 'option'} optionsValue={item} key={index}  optionClickValue={() => optionClick(index, item)}/>
                        ))
                    }


                   {props.filterItemValue.filterItemInputs && (
                        <p className="select_input_wrap">
                            <input type="number" name="from" className="form_field js_from" placeholder="від" onKeyUp={inputKeyUp} ref={inputFromRef}/>
                            <span> - </span>
                            <input type="number" name="to" className="form_field js_to" placeholder="до" onKeyUp={inputKeyUp} ref={inputToRef}/>
                        </p>
                    )}

                </div>
            </div>
        </div>
    )
}

export default FilterItem