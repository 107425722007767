import React from "react";
import './style.css'

function StatusDropdownOption(props){
    //console.log(typeof(props.bgValue))
    return (
        <div className={props.className} onClick={props.optionClickValue}>
            <div className="status_dropdown_option_icon" style={{backgroundColor: props.bgValue}}></div>
            <div className="status_dropdown_option_text_wr">
                <div className="status_dropdown_option_text">{props.titleValue}</div>
            </div>
        </div>
    )

}

export default StatusDropdownOption;